<template>
  <div class="col-12 c g">
    <div class="card card-body">
        <div class="col-12 table-responsive">
            <table class="table table-hover table-sm table-bordered">
                <thead>
                    <th>
                        العنوان
                    </th>
                    <th>
                        التاريخ
                    </th>
                    <th>
                        عدد الشهادات
                    </th>
                    <th>
                        يظهر في الرابط الموحد
                    </th>
                    <th>
                        تنتهي الصلاحية بعد
                    </th>
                    <th>
                        خيارات
                    </th>
                </thead>
                <tbody>
                    <tr v-for="result in results" :key="result._id">
                        <td>
                            {{ result.title }}
                            <a href="javascript:;" @click="editTitle(result)"><i class="fa fa-edit"></i></a>
                        </td>
                        <td>
                            {{ result.date }}
                        </td>
                        <td>
                            {{ result.students_numbers.length }}
                        </td>
                        <td>
                            <div class="form-check">
                              <label class="form-check-label">
                                <input type="checkbox" class="form-check-input" @change="toggle(result)" v-model="result.link" :value="true">
                                اظهار في الرابط الموحد
                              </label>
                            </div>
                        </td>
                        <td>
                            {{ days(result.date) }}
                        </td>
                        <td>
                            <a href="javascript:;" class="text-primary" @click="$router.push(`/_results/send?id=${result._id}`)">
                                <i class="fa fa-eye"></i>
                                استعراض / ارسال
                            </a>
                            &nbsp;
                            <a href="javascript:;" class="text-danger" @click="deleter(result._id)">
                                <i class="fa fa-trash"></i>
                                حذف
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            results: []
        }
    },
    created(){
        if(!checkPer("results")){
            this.$router.push('/NotPermitted')
            return false;
        }
        this.getResults()
    },
    methods: {
        getResults(){
            var g = this;
            $.post(api + '/user/results/history', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.results = r.response
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        editTitle(result){
            var title = prompt("عنوان العملية", result.title);
            var g = this;
            $.post(api + '/user/results/edit-title', {
                jwt: this.user.jwt,
                id: result._id,
                title: title
            }).then(function(r){
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.getResults()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        toggle(result){
            var g = this;
            $.post(api + '/user/results/toggle', {
                jwt: this.user.jwt,
                id: result._id,
                value: result.link
            }).then(function(r){
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.getResults()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        deleter(id){
            var g = this;
            if(!confirm("متاكد من حذف العملية؟")){return false;}
            $.post(api + '/user/results/delete', {
                jwt: this.user.jwt,
                id: id
            }).then(function(r){
                g.loaded = true;
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    g.getResults()
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        },
        days(date){
                var today = new Date(date);
                var tomorrow = new Date();
                tomorrow.setDate(today.getDate()+30);
                let date1 = new Date();
                let date2 = tomorrow;
                
                // Calculating the time difference
                // of two dates
                let Difference_In_Time =
                    date2.getTime() - date1.getTime();
                
                // Calculating the no. of days between
                // two dates
                let Difference_In_Days =
                    Math.round
                        (Difference_In_Time / (1000 * 3600 * 24));
            return `بعد ${Difference_In_Days} يوم`
 
        }
    }
}
</script>

<style>

</style>